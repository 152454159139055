import React from 'react';
import styled from 'styled-components';
import SEO from '../components/seo';

import Layout from '../components/layout';
import Page from '../components/Page';

const StyledPageWrapper = styled(Page.Wrapper)`
  min-height: calc(100vh - 275px);
  text-align: center;
  margin-top: 200px;
`;

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <StyledPageWrapper>

      <Page.Content>
        <Page.Title>Oh, No! Page not Found</Page.Title>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        <a href="/">Go to Homepage &gt; </a>

      </Page.Content>
    </StyledPageWrapper>
  </Layout>
);

export default NotFoundPage;
